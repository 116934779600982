// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig:{
    apiKey: "AIzaSyDaRb8_Vl-auUosF9ZwWHN-_QO_G-hmp-U",
    authDomain: "fig-app-31ba2.firebaseapp.com",
    projectId: "fig-app-31ba2",
    storageBucket: "fig-app-31ba2.appspot.com",
    messagingSenderId: "1061007038209",
    appId: "1:1061007038209:web:59c9a0e2549c25bb96ed00",
    measurementId: "G-P3SNZLW9NR"
  },
  featureds : ["Terry Withrow","Alex Pallios","Steven Zagaris"]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
