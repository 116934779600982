import { Component } from '@angular/core';
import 'firebase/analytics';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private analytics: AngularFireAnalytics) { }

  ngOnInit() {
    const firebaseConfig = {
      apiKey: "AIzaSyDaRb8_Vl-auUosF9ZwWHN-_QO_G-hmp-U",
      authDomain: "fig-app-31ba2.firebaseapp.com",
      projectId: "fig-app-31ba2",
      storageBucket: "fig-app-31ba2.appspot.com",
      messagingSenderId: "1061007038209",
      appId: "1:1061007038209:web:59c9a0e2549c25bb96ed00",
      measurementId: "G-P3SNZLW9NR"
    };
    
    // Initialize Firebase
    // const app = initializeApp(firebaseConfig);
    // const analytics = getAnalytics(app);
    // this.analytics.logEvent('username', { 
    //   'firsttimeuser':true,

    //   'username':'Zaheer'
    //  });
  }
  toggleData() {

  }

}
